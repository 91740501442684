import { Button, Card, Elevation } from "@blueprintjs/core";
import React = require("react");

export class DeleteDialog extends React.Component<{
	caption: React.ReactChild;
	onCancel: () => void;
	onSubmit: () => void;
}> {
	render() {
		return (
			<Card
				elevation={Elevation.FOUR}
				interactive={false}
				style={{ width: 400 }}
			>
				<h1 className="bp3-heading">{this.props.caption}</h1>

				<div style={{ display: "flex" }}>
					<div style={{ flex: 1 }} />
					<Button onClick={this.props.onCancel}>Cancel</Button>
					<div style={{ width: 8 }} />
					<Button intent="danger" onClick={this.props.onSubmit}>
						Delete
					</Button>
				</div>
			</Card>
		);
	}
}
