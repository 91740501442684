import { hotComponent } from "../../../../common/hotComponent";
import { observer } from "mobx-react";
import React = require("react");
import { Model } from "../../../model";
import {
	Card,
	Button,
	Elevation,
	Icon,
	AnchorButton,
	Overlay,
} from "@blueprintjs/core";
import { computed, observable, action } from "mobx";
import { fromPromise } from "mobx-utils";
import { pendingPromise } from "../../../../common/pendingPromise";
import { versionRoute, projectRoute, versionSettingsRoute } from "../../router";
import { PageLayout } from "../../PageLayout";
import { sVersionInfo } from "@hediet/i18n-api";
import { DeleteVersionDialog } from "./DeleteVersionDialog";

@hotComponent(module)
@observer
export class ProjectPage extends React.Component<
	{ model: Model; orgId: string; projectId: string },
	{}
> {
	@observable cacheId = 0;
	@computed get data() {
		this.cacheId;
		const { model, orgId, projectId } = this.props;
		const client = model.internalClient;
		return fromPromise(
			client
				? client.versionApi.getVersions({ orgId, projectId })
				: pendingPromise
		);
	}

	@computed get projectInfo() {
		const { model, orgId, projectId } = this.props;
		const client = model.internalClient;
		return fromPromise(
			client
				? client.mainApi.getProjectInfo({ orgId, projectId })
				: pendingPromise
		);
	}

	getNormalizedProjectInfo() {
		if (this.projectInfo.state === "fulfilled") {
			return this.projectInfo.value;
		}
		return {
			isAdmin: false,
			canDeleteVersions: false,
			canForkVersion: false,
			canConfigureVersion: false,
		};
	}

	private async fork(versionInfo: typeof sVersionInfo.T): Promise<void> {
		const { model, orgId, projectId } = this.props;
		await model.internalClient!.mainApi.createVersion({
			orgId,
			projectId,
			name: versionInfo.name,
			parents: [{ versionId: versionInfo.id }],
		});
		this.cacheId++;
	}

	@action
	private showDeleteDialog(versionInfo: typeof sVersionInfo.T): void {
		const { model, orgId, projectId } = this.props;
		model.dialog.show(
			<DeleteVersionDialog
				versionRef={{ orgId, projectId, versionId: versionInfo.id }}
				model={model}
				closeDialog={action(() => {
					model.dialog.close();
					this.cacheId++;
				})}
			/>
		);
	}

	render() {
		const { model, orgId, projectId } = this.props;

		if (this.data.state !== "fulfilled") {
			console.log("" + this.data.value);
		}
		return (
			<PageLayout
				model={model}
				breadcrumbs={[
					{ icon: "cube", text: orgId },
					{
						targetLocation: projectRoute.build({
							orgId: orgId,
							projectId: projectId,
						}),
						icon: "folder-close",
						text: projectId,
					},
				]}
			>
				<div className="component-ProjectPage">
					<h1 className="bp3-heading" style={{ paddingBottom: 16 }}>
						Project {projectId}
					</h1>
					<h2 className="bp3-heading">Versions</h2>

					{this.data
						.case({
							fulfilled: v => v.versions,
							pending: v => [],
							rejected: v => [],
						})
						.map(v => (
							<Card
								key={v.id}
								className="part-card"
								elevation={Elevation.TWO}
							>
								<div
									className="part-info"
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<a
										{...model.routing.locationToOnClick(
											versionRoute.build({
												orgId,
												projectId,
												versionId: v.id,
											})
										)}
									>
										<Icon icon="box" /> <b>{v.name}</b>
									</a>
									<div style={{ width: 16 }} />
									<div>Iteration {v.iteration}</div>
									<div style={{ width: 16 }} />
									{v.locked && (
										<div>
											<Icon icon="lock" />
										</div>
									)}
									<div style={{ marginLeft: "auto" }}>
										id: {v.id}
									</div>
									<div style={{ width: 8 }} />
									<div style={{ marginLeft: 8 }}>
										<AnchorButton
											icon="document-open"
											{...model.routing.locationToOnClick(
												versionRoute.build({
													orgId,
													projectId,
													versionId: v.id,
												})
											)}
										/>
									</div>
									{this.getNormalizedProjectInfo()
										.canConfigureVersion && (
										<div style={{ marginLeft: 8 }}>
											<AnchorButton
												icon="cog"
												{...model.routing.locationToOnClick(
													versionSettingsRoute.build({
														orgId,
														projectId,
														versionId: v.id,
													})
												)}
											/>
										</div>
									)}
									{this.getNormalizedProjectInfo()
										.canForkVersion && (
										<div style={{ marginLeft: 8 }}>
											<Button
												onClick={() => this.fork(v)}
												intent="success"
												icon="fork"
											/>
										</div>
									)}
									{this.getNormalizedProjectInfo()
										.canDeleteVersions && (
										<div style={{ marginLeft: 8 }}>
											<Button
												onClick={() =>
													this.showDeleteDialog(v)
												}
												intent="danger"
												icon="remove"
											/>
										</div>
									)}
								</div>
							</Card>
						))}
				</div>
			</PageLayout>
		);
	}
}
