import { Button, Card, Checkbox, Elevation, Icon } from "@blueprintjs/core";
import { observer } from "mobx-react";
import { Model } from "../../../model";
import { hotComponent } from "../../../../common/hotComponent";
import { PageLayout } from "../../PageLayout";
import { orgSettingsRoute } from "../../router";
import React = require("react");
import { OrganizationSettingsModel } from "./OrganizationSettingsModel";
import { MemberCard } from "./MemberCard";

@hotComponent(module)
@observer
export class OrganizationSettingsPage extends React.Component<
	{ model: Model; orgId: string },
	{}
> {
	private readonly model = new OrganizationSettingsModel(
		this.props.model,
		this.props.orgId
	);

	render() {
		const { model, orgId } = this.props;

		return (
			<PageLayout
				model={model}
				breadcrumbs={[
					{ icon: "cube", text: orgId },
					{
						targetLocation: orgSettingsRoute.build({
							orgId: orgId,
						}),
						icon: "cog",
						text: "Settings",
					},
				]}
			>
				<div className="component-ProjectPage">
					<h1 className="bp3-heading" style={{ paddingBottom: 16 }}>
						Organization {orgId}
					</h1>
					<div style={{ display: "flex", alignItems: "center" }}>
						<h2 className="bp3-heading">Members</h2>
						<div style={{ marginLeft: "auto", marginRight: 20 }}>
							<Button
								icon="plus"
								intent="success"
								onClick={this.model.showAddMemberDialog}
							/>
						</div>
					</div>

					{this.model.data
						.case({
							fulfilled: v => v.members,
							pending: v => [],
							rejected: v => [],
						})
						.map(v => (
							<MemberCard
								model={this.model}
								memberInfo={v}
								key={v.userId}
							/>
						))}
				</div>
			</PageLayout>
		);
	}
}
