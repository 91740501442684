import React = require("react");
import { Card, Button, Elevation } from "@blueprintjs/core";

export class NewOrganizationDialog extends React.Component<{
	onCancel: () => void;
	onSubmit: (user: {
		username: string;
		email: string;
		password: string;
		siteAdmin: boolean;
	}) => void;
}> {
	render() {
		return (
			<Card
				elevation={Elevation.FOUR}
				interactive={false}
				style={{ width: 400 }}
			>
				<h1 className="bp3-heading">New Organization</h1>
				<p>TODO</p>
				<div style={{ display: "flex" }}>
					<div style={{ flex: 1 }} />
					<Button onClick={this.props.onCancel}>Cancel</Button>
					<div style={{ width: 8 }} />
					<Button
						intent="success"
						onClick={() => this.props.onSubmit(null!)}
					>
						Add
					</Button>
				</div>
			</Card>
		);
	}
}
